import { useHasPermission } from 'admin-portal-shared-services';
import { permissions } from 'config/permissions';

type UsePermissions = {
  hasGroupPermission: boolean;
};

export const usePermissions = (): UsePermissions => {
  const hasGroupPermission = useHasPermission(
    [permissions.Group.READ, permissions.Group.WRITE],
    'OR'
  );

  return { hasGroupPermission };
};
